import React from 'react'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import {
  faUsers,
  faExclamationCircle,
  faLaptopCode,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons'

import banner1 from '../../../images/pages/bpm/bmp-processos.jpeg'
import banner2 from '../../../images/pages/bpm/bpm-processos-2.jpeg'

const slides = [
  {
    title: <>DESENHO DE PROCESSOS PONTA-A-PONTA</>,
    description: (
      <>
        Através da visão de processos ponta-a-ponta todos os envolvidos saberão
        quando e como agir. Utilizamos as notações reconhecidas mundialmente
        (BPMN 2.0 ou EPC).
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: (
      <>
        A PADRONIZAÇÃO DE PROCESSOS É FUNDAMENTAL PARA IMPLANTAR MELHORIAS E
        SISTEMAS DE GESTÃO
      </>
    ),
    description: (
      <>
        Utilize a padronização antes mesmo de otimizar processos e escolher o
        sistema mais aderente ao seu negócio, seja um ERP ou CRM.
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `P4 Engenharia`,
    text: `Tarefas repetitivas passam a ser executadas por Robos`,
    quantity: 74,
  },
  {
    title: `SASCAR`,
    text: `Aderências ao sistema Totvs`,
    quantity: 32,
  },
  {
    title: `UNIMED Rio Preto`,
    text: `Projeto de transformção digital e redução de atividades manuais`,
    quantity: 96,
  },
  {
    title: `Okubo`,
    text: `Estruturação da área comercial e aumento da aderência aos sistemas da empresa`,
    quantity: 40,
  },
]

const MelhoriaDeProcessos: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faUsers,
      title: `Pessoas envolvidas com o processo`,
      description: (
        <>
          Nesta etapa, serão analisadas as pessoas envolvidas com o processo,
          necessidade de contratação, realocação e treinamentos. É preciso
          entender o ser humano, seus limites e potenciais, assim como o cuidado
          com a clareza de informações, envolvimento da equipe e boa comunicação
          para gerir processos.
        </>
      ),
    },
    {
      faIcon: faExclamationCircle,
      title: `Riscos e controles internos`,
      description: (
        <>
          Serão analisados os principais riscos inerentes ao processo e
          identificados pontos de controle para que o processo seja conforme e
          seus riscos e impactos sejam mapeados e tratados.
        </>
      ),
    },
    {
      faIcon: faLaptopCode,
      title: `Sistemas de Informação`,
      description: (
        <>
          Além de entender a lógica do fluxo de informação, eliminar
          retrabalhos, excesso de aprovações, serão identificadas oportunidades
          de sistematização e automação de fluxos de trabalho. Nesta etapa é
          proposta também uma análise de aderência do sistema utilizado ou qual
          se deseja implantar.
        </>
      ),
    },
    {
      faIcon: faChartLine,
      title: `Indicadores de Processos`,
      description: (
        <>
          Serão analisados indicadores de desempenho de processos (KPIs) para
          medir os resultados de determinadas ações ou estratégias utilizadas.
          Se os resultados esperados não estiverem sendo atingidos, os KPIs
          permitirão enxergar os gaps e tomar decisões assertivas para
          eliminá-los.
        </>
      ),
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Planejamento',
      description: (
        <>
          Durante o planejamento, a cocriação dos objetivos é a melhor
          ferramenta para o sucesso do projeto. Serão definidos escopo,
          objetivos e partes interessadas. Ainda nesta etapa será mobilizada a
          equipe especializada para atendimento da demanda.
        </>
      ),
    },
    {
      count: 2,
      title: 'Mapeamento',
      description: (
        <>
          Na etapa de mapeamento será realizado inicialmente um workshop para
          engajamento dos colaboradores, o que minimiza o risco de resistência
          às mudanças que serão propostas durante o projeto. Em seguida, serão
          levantadas toda a documentação existente e mapeados os processos
          atuais (AS IS).
        </>
      ),
    },
    {
      count: 3,
      title: 'Análise e Melhoria',
      description: (
        <>
          Após o mapeamento do processo atual, etapa que é realizada junto aos
          donos de processos, ouvindo suas dores, identificando riscos e
          oportunidades, serão levantadas as melhorias do processo para
          aprovação. Após aprovação das melhorias, será modelado o novo processo
          TO BE e extraídos os indicadores de desempenho (KPIs) para
          monitoramento da performance.
        </>
      ),
    },
    {
      count: 4,
      title: 'Implementação',
      description: (
        <>
          Para cada melhoria identificada e aprovada serão gerados planos de
          ação que conduzirão à mudança. Serão utilizadas técnicas de GMO do
          Instituto HCMI para engajar e "puxar" as mudanças para que não fiquem
          só no papel. Com reuniões periódicas unem-se os agentes da mudança
          para reportarem as necessidades ou dificuldades da implantação.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="Mapeamento e melhoria de processos - BPM - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="
              Melhorar processos de negócio, seja com a finalidade de aumentar o
              desempenho ou mesmo para atender normas e conquistar certificações, como
              ISO 9001, RN 443, SOX, etc. Conheça as principais variáveis a serem
              analisadas durante o projeto."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default MelhoriaDeProcessos
